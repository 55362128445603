import FormControl from '@material-ui/core/FormControl';
import { Modal } from 'react-bootstrap';
import styled from 'styled-components';
import { AiOutlineClose } from 'react-icons/ai';
import {
  mobileS, mobileM, tablet, desktop, mobileL,
} from '../../responsive';

export const FormContainer = styled.div`
  display: flex;

  justify-content: flex-start;
  margin: 10px;
  align-items: center;
  ${mobileS({
    flexDirection: 'column',
    alignSelf: 'center',
    margin: '0',
  })}
  ${tablet({
    flexDirection: 'column',
    justifyContent: 'flex-start',
  })}
  ${desktop({
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  })}
`;
export const FindHeader = styled.div`
  font-size: 1.2em;
  border-radius: 8px 8px 0 0;
  background-color: #edf9ee;
  padding: 7px 9px;
  ${mobileS({
    textAlign: 'center',
  })}
  ${tablet({
    textAlign: 'center',
  })}
`;
export const FindContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;
export const FindWrapper = styled.div`
  box-shadow: 0 1px 2px 1px #a3d4cb;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: white;
  width: 30%;
  border-radius: 8px;
  position: absolute;
  top: -6em;
  font-family: oswald;
  ${mobileS({
    width: '94%',
    top: '-26em',
  })}

  ${tablet({
    width: '95%',
  })}
  ${desktop({
    width: '84%',
    top: '-8em',
  })}
  @media screen and (min-width:1440px) {
    width: 75%;
    top: -5em;
  }
`;
export const SpanTxt = styled.span`
  margin: 10px;
  @media screen and (min-width: 320px) {
    display: none;
  }
`;
export const BottomContainer = styled.div`
  padding: 10px 20px;
  @media screen and (min-width: 320px) {
    padding: 1em;
  }
`;
export const UploadBtn = styled.span`
  /* box-shadow: 0 1px 2px rgb(60 64 67 / 30%), 0 1px 3px 1px rgb(60 64 67 / 15%); */
  /* box-shadow: 2px 4px 25px rgb(0 0 0 / 25%); */
  white-space: nowrap;
  color: #1d9682;
  font-family: oswald;
  font-size: 20px;
  border-radius: 10px;
  cursor: pointer;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  -webkit-text-decoration: none;
  text-decoration: none;
  text-align: "center";
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    transition: all 0.2sease-in-out;
    //color: white;
  }
`;
export const UploadBtn2 = styled.button`
  /* box-shadow: 0 1px 2px rgb(60 64 67 / 30%), 0 1px 3px 1px rgb(60 64 67 / 15%); */
  /* box-shadow: 2px 4px 25px rgb(0 0 0 / 25%); */
  padding: 1px 50px;
  box-shadow: 0 1px 2px 1px #a3d4cb;
  margin: 10px 0;
  white-space: nowrap;
  cursor: pointer;
  color: white;
  background: #1d9682;
  font-family: oswald;
  font-size: 20px;
  border-radius: 8px;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  -webkit-text-decoration: none;
  text-decoration: none;
  text-align: "center";
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    transition: all 0.2sease-in-out;
    //color: white;
  }
`;
export const FindPharmBtn = styled.button`
  box-shadow: 0 1px 2px rgb(60 64 67 / 30%), 0 1px 3px 1px rgb(60 64 67 / 15%);
  /* box-shadow: 2px 4px 25px rgb(0 0 0 / 25%); */
  border-radius: 10px;
  white-space: nowrap;
  color: #1d9682;
  font-size: 20px;
  outline: 2.5px solid #1d9682;
  border: none;
  cursor: pointer;
  padding: 7px 30px;
  margin-left: 20px;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  -webkit-text-decoration: none;
  text-decoration: none;
  text-align: "center";

  &:hover {
    transition: all 0.2sease-in-out;
    background: #ffffffa3;
    color: #1d9682;
  }
  ${mobileS({
    margin: '1em 0',
    width: '100%',
  })}
  ${desktop({
    margin: '10px',
    padding: '10px 15px',
    width: 'initial',
  })}
`;
export const StyledFormControl = styled(FormControl)`
  border: 2px dotted #1d9682;
  cursor: pointer;
  border-radius: 10px;
  padding: 1em;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    transition: all 0.2sease-in-out;
    background: #2e4158;
    color: #f8f9fa;
  }
  @media screen and (min-width: 320px) {
    width: 100%;
    height: 111px;
    padding: 10px;
  }
  @media screen and (min-width: 375px) {
  }
  @media screen and (min-width: 425px) {
  }
  @media screen and (min-width: 768px) {
  }
  @media screen and (min-width: 1024px) {
  }
  @media screen and (min-width: 1440px) {
  }
`;
export const StyledLabel = styled.label`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  @media screen and (min-width: 320px) {
    width: 100%;
    height: 100%;
  }
`;
export const PrescriptionImg = styled.img`
  @media screen and (min-width: 320px) {
    width: 60%;
  }
`;
export const StyledModal = styled(Modal)`
  font-family: oswald;
  
`;

export const ContentWrapper = styled.div`
  text-align: center;
  padding: 2em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 600px;
  max-height: 400px;
`;
export const ModalSvg = styled.img`
  width: 70%;
`;
export const ModalTxt = styled.p`
  font-size: 1.5em;
`;
export const ModalDesc = styled.p`
  font-size: 1.2em;
  font-weight: 300;
`;
export const IconCont = styled.div`
  align-self: end;
`;
export const CloseIcon = styled(AiOutlineClose)`
  cursor: pointer;
  margin-bottom: 20px;
  color: #1d9682;
  align-self: flex-end;
  font-size: 20px;
`;
